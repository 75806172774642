import { MONTH_DICT } from '@/consts';
import { withLeadingZero } from '../strings';

export function formatDate(date: Date, separator = '.', showYear = true) {
    return [withLeadingZero(date.getDate()), withLeadingZero(date.getMonth() + 1), showYear ? date.getFullYear() : null]
        .filter(Boolean)
        .join(separator);
}

/**
 *
 * @param startDate ex: 01.05.2025
 * @param endDate ex: 02.05.2025
 * @returns ex: 1-2 мая 2025
 */
export const formatDateRange = (startDate: string, endDate: string): string => {
    const start = startDate.split('.').map(Number) as [number, keyof typeof MONTH_DICT, number];
    const end = endDate.split('.').map(Number) as [number, keyof typeof MONTH_DICT, number];

    const isSameDay = start[0] === end[0];
    const isSameYear = start[2] === end[2];
    const isSameMonth = isSameYear && start[1] === end[1];

    if (isSameYear && isSameMonth && isSameDay) {
        return `${start[0]} ${MONTH_DICT[start[1]]} ${start[2]}`;
    } else if (isSameYear && isSameMonth) {
        return `${start[0]}-${end[0]} ${MONTH_DICT[start[1]]} ${start[2]}`;
    } else if (isSameYear && isSameDay) {
        return `${start[0]} ${MONTH_DICT[start[1]]} - ${MONTH_DICT[end[1]]} ${end[2]}`;
    } else if (isSameYear) {
        return `${start[0]} ${MONTH_DICT[start[1]]} - ${end[0]} ${MONTH_DICT[end[1]]} ${end[2]}`;
    } else {
        return `${start[0]} ${MONTH_DICT[start[1]]} ${start[2]} - ${end[0]} ${MONTH_DICT[end[1]]} ${end[2]}`;
    }
};
